<template>
  <div class="border">
    <h2>the Grim Reaper Timer 利用規約</h2>
    <div class="line"></div>
    <p>この利用規約（以下，「本規約」といいます。）は，<span>the Grim Reaper Timer</span> 運営（以下，「当方」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。</p>
    <div class="content">
      <h3>第1条（適用）</h3>
      <ol>
        <li>本規約は，ユーザーと当方との間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
        <li>当方は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</li>
        <li>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第2条（利用登録）</h3>
      <ol>
        <li>本サービスにおいては，登録希望者が本規約に同意の上，当方の定める方法によって利用登録を申請し，当方がこの承認を登録希望者に通知することによって，利用登録が完了するものとします。</li>
        <li>当方は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。</li>
        <ol>
          <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
          <li>本規約に違反したことがある者からの申請である場合</li>
          <li>その他，当方が利用登録を相当でないと判断した場合</li>
        </ol>
      </ol>
    </div>
    <div class="content">
      <h3>第3条（アカウント情報の管理）</h3>
      <ol>
        <li>ユーザーは，自己の責任において，本サービスのアカウント情報(メールアドレスおよびパスワード)を適切に管理するものとします。</li>
        <li>ユーザーは，いかなる場合にも，アカウント情報(メールアドレスおよびパスワード)を第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当方は，アカウント情報(メールアドレスおよびパスワード)の組み合わせが登録情報と一致してログインされた場合には，そのアカウントを登録しているユーザー自身による利用とみなします。</li>
        <li>アカウント情報(メールアドレスおよびパスワード)が第三者によって使用されたことによって生じた損害は，当方に故意又は重大な過失がある場合を除き，当方は一切の責任を負わないものとします。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第4条（禁止事項）</h3>
      ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
      <ol>
        <li>法令または公序良俗に違反する行為</li>
        <li>犯罪行為に関連する行為</li>
        <li>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</li>
        <li>当方，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
        <li>本サービスによって得られた情報を商業的に利用する行為</li>
        <li>当方のサービスの運営を妨害するおそれのある行為</li>
        <li>不正アクセスをし，またはこれを試みる行為</li>
        <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
        <li>他のユーザーに成りすます行為</li>
        <li>当方のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
        <li>当方，本サービスの他のユーザーまたは第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為</li>
        <li>以下の表現を含み，または含むと当方が判断する内容を本サービス上に投稿し，または送信する行為</li>
        <ol>
          <li>過度に暴力的な表現</li>
          <li>露骨な性的表現</li>
          <li>人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現</li>
          <li>自殺，自傷行為，薬物乱用を誘引または助長する表現</li>
          <li>その他反社会的な内容を含み他人に不快感を与える表現</li>
        </ol>
        <li>以下を目的とし，または目的とすると当方が判断する行為</li>
        <ol>
          <li>営業，宣伝，広告，勧誘，その他営利を目的とする行為（当方の認めたものを除きます。）</li>
          <li>性行為やわいせつな行為を目的とする行為</li>
          <li>面識のない異性との出会いや交際を目的とする行為</li>
          <li>他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為</li>
          <li>当方，本サービスの他のユーザー，または第三者に不利益，損害または不快感を与えることを目的とする行為</li>
          <li>その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為</li>
        </ol>
        <li>宗教活動または宗教団体への勧誘行為</li>
        <li>その他，当方が不適切と判断する行為</li>
      </ol>
    </div>
    <div class="content">
      <h3>第5条（本サービスの提供の停止等）</h3>
      <ol>
        <li>当方は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</li>
        <ol>
          <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
          <li>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</li>
          <li>コンピュータまたは通信回線等が事故により停止した場合</li>
          <li>その他，当方が本サービスの提供が困難と判断した場合</li>
        </ol>
        <li>当方は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第6条（利用制限および登録抹消）</h3>
      <ol>
        <li>当方は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。</li>
        <ol>
          <li>本規約のいずれかの条項に違反した場合</li>
          <li>登録事項に虚偽の事実があることが判明した場合</li>
          <li>当方からの連絡に対し，一定期間返答がない場合</li>
          <li>本サービスについて，最終の利用から一定期間利用がない場合</li>
          <li>その他，当方が本サービスの利用を適当でないと判断した場合</li>
        </ol>
        <li>当方は，本条に基づき当方が行った行為によりユーザーに生じた損害について，一切の責任を負いません。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第7条（退会）</h3>
      <ol>
        <li class="only">ユーザーは，当方の定める退会手続により，本サービスから退会できるものとします。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第8条（保証の否認および免責事項）</h3>
      <ol>
        <li>当方は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。当方は、ユーザーに対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。</li>
        <li>当方は，本サービスに起因してユーザーに生じたあらゆる損害について、当方の故意又は重過失による場合を除き、一切の責任を負いません。</li>
        <li>当方は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第9条（サービス内容の変更等）</h3>
      <ol>
        <li class="only">当方は，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第10条（利用規約の変更）</h3>
      <ol>
        <li>当方は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。</li>
        <ol>
          <li>本規約の変更がユーザーの一般の利益に適合するとき。</li>
          <li>本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。</li>
        </ol>
        <li>当方はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第11条（個人情報の取扱い）</h3>
      <ol>
        <li class="only">当方は，本サービスの利用によって取得する個人情報については，当方「プライバシーポリシー」に従い適切に取り扱うものとします。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第12条（通知または連絡）</h3>
      <ol>
        <li class="only">ユーザーと当方との間の通知または連絡は，当方の定める方法によって行うものとします。当方は,ユーザーから,当方が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第13条（権利義務の譲渡の禁止）</h3>
      <ol>
        <li class="only">ユーザーは，当方の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</li>
      </ol>
    </div>
    <div class="content">
      <h3>第14条（準拠法・裁判管轄）</h3>
      <ol>
        <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
        <li>本サービスに関して紛争が生じた場合には，当方の本店所在地を管轄する裁判所を専属的合意管轄とします。</li>
      </ol>
    </div>
    <p class="end">以上</p>
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    mounted () {
      window.scrollTo(0, 0);
    },
    methods: {
    }
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap');

  div.border {
    margin: 0 auto;
    width: 1100px;
  }
  h2 {
    font-family: 'IM Fell English SC', serif;
    text-align: center;
    margin: 0 auto;
    border-bottom: 1px solid #D9D9D9;
    width: 500px;
    margin-top: 70px;
    margin-bottom: 50px;
  }
  span {
    font-family: 'IM Fell English SC', serif;
  }
  .content {
    margin: 50px 0px;
  }
  p.end {
    text-align: right;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  li.only {
    list-style: none;
  }
</style>