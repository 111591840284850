<template>
  <h2>アカウント削除機能は只今準備中です</h2>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
 h2 {
  text-align: center;
  padding: 170px;
 }
</style>