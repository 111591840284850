<template>
  <PasswordReset />
  <FooterLink />
</template>

<script>
  import PasswordReset from '../components/PasswordReset.vue'
  import FooterLink from '../components/FooterLink.vue'

  export default {
    components: { PasswordReset, FooterLink },

    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>