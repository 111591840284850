<template>
  <PrivacyPolicy />
</template>

<script>
  import PrivacyPolicy from '../components/PrivacyPolicy.vue'

  export default {
    components: { PrivacyPolicy },

    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>