<template>
  <TermsOfService />
</template>

<script>
  import TermsOfService from '../components/TermsOfService.vue'

  export default {
    components: { TermsOfService },

    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>